import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import { FaArrowUp } from "@react-icons/all-files/fa/FaArrowUp";
import { FaLeaf } from "@react-icons/all-files/fa/FaLeaf";
import { FaCalendarDay } from "@react-icons/all-files/fa/FaCalendarDay";
import { FaCar } from "@react-icons/all-files/fa/FaCar";
import { FaCloudDownloadAlt } from "@react-icons/all-files/fa/FaCloudDownloadAlt";

const SolutionGridTwo = () => {
  return (
    <section className="solution-grid-two">
      <div className="relative w-screen flex justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 w-screen lg:w-1000 gap-x-20 gap-y-8 lg:gap-y-28 pt-16 sm:-mt-16 lg:mt-0 lg:pt-28 pb-16 md:pb-60 z-30">
          <div className="flex flex-col justify-center items-start lg:items-center order-2 lg:order-2 px-4 lg:px-0 pb-12 lg:pb-0 mt-8 md:mt-0">
            <div className="max-w-500">
              <h2 className="text-2xl md:text-4xl font-medium text-gray-700 py-2 w-full md:pl-8 ">
                <FormattedMessage id="solution_pricing_heading" />
              </h2>
              <div className="md:pl-8 md:border-l md:border-gray-100 mt-6">
                <h3 className="text-base font-medium text-gray-700 w-full ">
                  <FormattedMessage id="solution_pricing_volatility_title" />
                </h3>
                <p className="text-sm font-light text-gray-500 mt-3 w-full ">
                  <FormattedMessage id="solution_pricing_volatility_text" />
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-center sm:justify-end order-1 lg:order-2 lg:relative sm:mt-10 lg:mt-20">
            <div className="w-full max-w-[400px] md:max-w-[500px] h-60 md:h-80 relative">
              <div className="absolute top-0 lg:-right-14 lg:-top-28 lg:max-w-[120%]">
                <StaticImage
                  src="../../images/animations/graph.png"
                  alt="Electricity price graph"
                  placeholder="blurred"
                  layout="constrained"
                  loading="lazy"
                />
              </div>

              <div
                className="absolute -top-14 lg:right-16 lg:-top-40 max-w-[95%]"
                id="notification-animation"
              >
                <StaticImage
                  src="../../images/animations/notification.png"
                  alt="Electricity price change notification"
                  placeholder="blurred"
                  layout="constrained"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center sm:justify-end order-3 w-full">
            <div className="relative vertical-carousel h-60 md:h-80 w-80 md:w-96 mr-0 sm:mr-6 lg:mr-0 z-10">
              <div className="grid grid-cols-4 h-16 md:h-20 w-80 md:w-96 items-center rounded-md px-4 absolute left-0 carousel-child-1 opacity-0">
                <div className="col-span-1 text-2xl pl-4">
                  <FaArrowUp />
                </div>
                <div className="col-span-3 text-sm font-light text-gray-700">
                  <p>
                    Your total consumption is up{" "}
                    <span className="font-bold">14%</span> compared to last
                    month
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 h-16 md:h-20 w-80 md:w-96 items-center rounded-md px-4 absolute left-0 carousel-child-2 opacity-0">
                <div className="col-span-1 text-2xl pl-4">
                  <FaLeaf />
                </div>
                <div className="col-span-3 text-sm font-light text-gray-700">
                  <p>
                    The share of renewables on the grid is{" "}
                    <span className="font-bold">62%</span> right now
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-4 h-16 md:h-20 w-80 md:w-96 items-center rounded-md px-4 absolute left-0 carousel-child-3 opacity-0">
                <div className="col-span-1 text-2xl pl-4">
                  <FaCalendarDay />
                </div>
                <div className="col-span-3 text-sm font-light text-gray-700">
                  <p>
                    Construction of the solar park is completed in
                    <span className="font-bold"> 118</span> days
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 h-16 md:h-20 w-80 md:w-96 items-center rounded-md px-4 absolute left-0 carousel-child-4 opacity-0">
                <div className="col-span-1 text-2xl pl-4">
                  <FaCar />
                </div>
                <div className="col-span-3 text-sm font-light text-gray-700">
                  <p>
                    You have saved{" "}
                    <span className="font-bold">
                      97t CO<sub>2</sub>
                    </span>{" "}
                    - equivalent to taking 21 cars of the road
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 h-16 md:h-20 w-80 md:w-96 items-center rounded-md px-4 absolute left-0 carousel-child-5 opacity-0">
                <div className="col-span-1 text-2xl pl-4">
                  <FaCloudDownloadAlt />
                </div>
                <div className="col-span-3 text-sm font-light text-gray-700">
                  <p>
                    Download CSR report{" "}
                    <span className="font-bold underline"> here</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start lg:items-center order-4 px-4 lg:px-0 pb-12 lg:pb-0 mt-16 sm:mt-0">
            <div className="max-w-500">
              <h2 className="text-2xl md:text-4xl font-medium text-gray-700 py-2 w-full md:pl-8">
                <FormattedMessage id="solution_reporting_heading" />
              </h2>
              <div className="md:pl-8 md:border-l md:border-gray-100 mt-6">
                <h3 className="text-base font-medium text-gray-700 w-full ">
                  <FormattedMessage id="solution_reporting_csr_title" />
                </h3>
                <p className="text-sm font-light text-gray-500 mt-3 w-full ">
                  <FormattedMessage id="solution_reporting_csr_text" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionGridTwo;

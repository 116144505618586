import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";

import SolutionHero from "../components/solution/hero";
import SolutionGridOne from "../components/solution/gridOne";
import SolutionGridTwo from "../components/solution/gridTwo";
import SolutionComparisonTable from "../components/solution/table";

const Solution = () => {
  return (
    <Layout isLight={true}>
      <Seo
        title="Solution"
        description="Transforming electricity procurement for climate action"
      />
      <SolutionHero></SolutionHero>
      <SolutionGridOne></SolutionGridOne>
      <SolutionComparisonTable></SolutionComparisonTable>
      <SolutionGridTwo></SolutionGridTwo>
    </Layout>
  );
};
export default Solution;
